<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Neuer Veranstaltungsort">
      <event-location-form
        :location="location"
        submit-label="Neuen Veranstaltungsort anlegen"
        :form-submitted="saveLocation"
        mode="new"
      ></event-location-form>
    </page-content>
  </div>
</template>

<script>
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import EventLocationForm from "@/components/forms/EventLocationForm"
import EventLocationsRepository from "@/repositories/event_locations_repository.js"

export default {
  metaInfo: {
    title: "Neuer Veranstaltungsort"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    EventLocationForm
  },
  data() {
    return {
      location: {}
    }
  },
  async created() {},
  methods: {
    async saveLocation(location) {
      await EventLocationsRepository.create(location)
      this.$router.push({ name: "EventLocationsPage" })
    }
  }
}
</script>
